import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { getNanoBotLoading, getNanoBotMessagesHistory } from 'modules/nanoBot/nanoBotSlice';
import { CallToAction } from 'modules/nanoBot/types';

import { ChatLoadingMessage } from './ChatLoadingMessage';
import { ChatMessage } from './ChatMessage';
import { NanoBotWelcomeMessage } from './NanoBotWelcomeMessage';

type MessageProps = {
  cta: CallToAction[] | null;
};

export const ChatArea = ({ cta }: MessageProps) => {
  const messagesHistory = useSelector(getNanoBotMessagesHistory);
  const isLoading = useSelector(getNanoBotLoading);
  const chatAreaRef = useRef<HTMLDivElement>(null);
  const intentPrompt: string[] = cta && cta[0] ? cta[0].actionValue : [];

  useEffect(() => {
    if (chatAreaRef.current) {
      chatAreaRef.current.scrollTop = chatAreaRef.current.scrollHeight;
    }
  }, [messagesHistory, chatAreaRef]);

  const messageSection = cta && cta.length > 0 ? 'flex-1 h-[calc(100vh-250px)]' : 'h-3/5';

  return (
    <section
      ref={chatAreaRef}
      className={`${messageSection} overflow-y-auto px-[25px] text-secondary-white`}
    >
      <NanoBotWelcomeMessage />
      {messagesHistory?.length > 0 &&
        messagesHistory.map((message, index) => (
          <ChatMessage
            key={index}
            messageContent={message}
            intentPrompt={intentPrompt}
            actions={cta}
            isLastMessage={index === messagesHistory.length - 1}
          />
        ))}
      {isLoading && <ChatLoadingMessage />}
    </section>
  );
};
