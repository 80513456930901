import { TargetingOptionPath } from 'modules/segment/segmentForms/ManualStepForm/types';

import {
  CUSTOM_CATEGORY_ROOT,
  CustomCategoryMap,
  NEW_SEGMENT_ROOT,
  NewSegmentPagesMap,
  Route,
  SEGMENT_ROOT,
  SegmentPagesMap,
} from './constants';

type NewSegmentPage = (typeof NewSegmentPagesMap)[keyof typeof NewSegmentPagesMap];
type SegmentPage = (typeof SegmentPagesMap)[keyof typeof SegmentPagesMap];
type CustomCategoryPage = (typeof CustomCategoryMap)[keyof typeof CustomCategoryMap];

export const getNewSegmentRoute = (page: NewSegmentPage): Route =>
  `${NEW_SEGMENT_ROOT}/${page}` as Route;

export const getNewSegmentManualRoute = (targetingType: TargetingOptionPath): Route =>
  getNewSegmentRoute(`${NewSegmentPagesMap.manual}/${targetingType}`);

export const getSegmentRoute = (page: SegmentPage): Route => `${SEGMENT_ROOT}/${page}` as Route;
export const getCustomCategoryRoute = (page: CustomCategoryPage): Route =>
  `${CUSTOM_CATEGORY_ROOT}/${page}` as Route;

export const composeSegmentByIdRoute = (id: string) => getSegmentRoute(id);
export const composeCustomCategoryByIdRoute = (id: string) => getCustomCategoryRoute(id);

export const composeSegmentSaveRoute = (id: string | undefined) =>
  getSegmentRoute(`${id}/${SegmentPagesMap.save}`);
