import { useTranslation } from 'react-i18next';

import { Text } from 'components/common';
import { NanoBotImg } from 'components/common/nanoBot';

export const NanoBotHeader = () => {
  const { t } = useTranslation();

  return (
    <header className="flex items-center px-[24px] py-[16px] bg-primary-dark-purple-120">
      <div className="mr-[10px]">
        <NanoBotImg className="h-[44px] w-[44px]" />
      </div>
      <div>
        <div className="text-text-5 text-lg font-semibold">{t('nanoBot.title')}</div>
        <div className="flex items-center justify-center mt-1">
          <div className="mr-[5px] h-[8px] w-[8px] rounded-full bg-secondary-teal" />
          <Text className="text-text-20">{t('nanoBot.status')}</Text>
        </div>
      </div>
    </header>
  );
};
