import { sessionStorageService } from 'services/sessionStorage';

import { CallToAction, ResponseMessage } from './types';

const NANO_BOT_KEY = 'nanoBotMessagesHistory';
const CTA_KEY = 'nanoBotCat';

const saveToNanoBotStorage = (data: ResponseMessage[]) => {
  const currentData: ResponseMessage[] = sessionStorageService.getItem(NANO_BOT_KEY) || [];

  sessionStorageService.setItem(NANO_BOT_KEY, [...currentData, ...data]);
};

const getFromNanoBotStorage = (): ResponseMessage[] | [] => {
  return sessionStorageService.getItem(NANO_BOT_KEY) || [];
};

const clearNanoBotStorage = () => {
  sessionStorageService.removeItem(NANO_BOT_KEY);
};

const saveCtaToNanoBotStorage = (data: CallToAction[] | null) => {
  sessionStorageService.setItem(CTA_KEY, data);
};

const getCtaFromNanoBotStorage = (): CallToAction[] | [] => {
  return sessionStorageService.getItem(CTA_KEY) || [];
};

const clearCtaNanoBotStorage = () => {
  sessionStorageService.removeItem(CTA_KEY);
};

export const nanoBotStorage = {
  saveToNanoBotStorage,
  getFromNanoBotStorage,
  clearNanoBotStorage,
  saveCtaToNanoBotStorage,
  getCtaFromNanoBotStorage,
  clearCtaNanoBotStorage,
};
