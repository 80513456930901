export enum DialogNames {
  CONFIRMATION_DIALOG = 'CONFIRMATION_DIALOG',
  TRACKING_TAGS_DIALOG = 'TRACKING_TAGS_DIALOG',
  SEGMENT_LABELS_DIALOG = 'MANAGE_LABELS_DIALOG',
  COMPANY_FORM_DIALOG = 'COMPANY_FORM_DIALOG',
  TEAM_FORM_DIALOG = 'TEAM_FORM_DIALOG',
  EXCLUSION_LIST_FORM_DIALOG = 'EXCLUSION_LIST_FORM_DIALOG',
  AUDIT_LOG_DIALOG = 'AUDIT_LOG_DIALOG',
  EXPIRED_DATE_DIALOG = 'EXPIRED_DATE_DIALOG',
  SEGMENT_GROUP_FORM_DIALOG = 'SEGMENT_GROUP_FORM_DIALOG',
  DOMAIN_LIST_FORM_DIALOG = 'DOMAIN_LIST_FORM_DIALOG',
  SEGMENTS_LIST_DIALOG = 'SEGMENTS_LIST_DIALOG',
  UPDATE_INTENT_PROMPT_DIALOG = 'UPDATE_INTENT_PROMPT_DIALOG',
}

export interface DialogsListEntry {
  id: string;
  name: DialogNames;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: any;
}

export interface DialogState {
  list: Array<DialogsListEntry>;
}

export interface DialogBaseProps {
  open: boolean;
  onClose: () => void;
}
