import classNames from 'classnames';
import { Plus } from 'feather-icons-react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button, Text, Tooltip } from 'components/common';
import { NanoBotImg } from 'components/common/nanoBot';

import { CallToAction, NanoBotChatRole } from 'modules/nanoBot/types';
import { getIsSegmentLoading } from 'modules/segment/segmentSelectors';
import { saveSegmentNanoBot, setTypeByIntentPrompt } from 'modules/segment/segmentSlice';

type MessageProps = {
  messageContent?: {
    role: (typeof NanoBotChatRole)[keyof typeof NanoBotChatRole];
    content: string;
  };
  intentPrompt: string[];
  actions: CallToAction[] | null;
  isLastMessage: boolean;
};

export const ChatMessage = ({
  messageContent,
  intentPrompt,
  actions,
  isLastMessage,
}: MessageProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAssistantMessage = messageContent?.role === NanoBotChatRole.Assistant;
  const isLoadingSegment = useSelector(getIsSegmentLoading);

  const hasCreateSegment =
    actions?.some((action) => action.actionName === 'create segment') ?? false;

  const classes = useMemo(() => {
    return {
      messageContainerClasses: classNames('mb-[30px]', 'flex', {
        'justify-end': !isAssistantMessage,
      }),
      messageContentClasses: classNames(
        'w-4/5',
        'rounded-bl-[24px]',
        'rounded-br-[24px]',
        'rounded-tr-[24px]',
        'bg-primary-dark-purple-80',
        'p-[20px]',
        {
          'rounded-tl-[24px]': !isAssistantMessage,
          'bg-secondary-green/50': !isAssistantMessage,
        },
      ),
    };
  }, [isAssistantMessage]);

  const handleCreateSegment = () => {
    dispatch(setTypeByIntentPrompt(true));
    dispatch(saveSegmentNanoBot({ intentPrompt, navigate }));
  };

  return (
    <div className={classes.messageContainerClasses}>
      {isAssistantMessage && <NanoBotImg className="mr-[5px] h-[32px] w-[32px]" />}
      <div className={classes.messageContentClasses}>
        <Text className="whitespace-pre-wrap" style={{ color: '#fff' }}>
          {messageContent?.content}
          {isAssistantMessage && isLastMessage && hasCreateSegment && (
            <div className="flex flex-row justify-end">
              <Tooltip overlay={<div className="p-2">{t('nanoBot.buttons.create')}</div>}>
                <Button
                  onClick={handleCreateSegment}
                  variant="primary"
                  isLoading={isLoadingSegment}
                  className="self-end mt-4"
                >
                  <span> {t('pages.management.newSegment')}</span> <Plus className="ml-2" />
                </Button>
              </Tooltip>
            </div>
          )}
        </Text>
      </div>
    </div>
  );
};
