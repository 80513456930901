import { t } from 'i18next';
import { useState } from 'react';

import { Input, SubmitButton } from 'components/common';

import { DialogBaseProps } from '../dialog';
import { NanoDialog } from '../dialog/NanoDialog/NanoDialog';

export interface UpdateIntentPromptDialogProps extends DialogBaseProps {
  title?: string | undefined;
  intentPrompt: string[];
  onSave: (updatedPrompt: string[]) => void; // Callback to return updated values
}

export const UpdateIntentPromptDialog = ({
  open,
  onClose,
  title,
  intentPrompt,
  onSave,
}: UpdateIntentPromptDialogProps) => {
  const [updatedPrompt, setUpdatedPrompt] = useState<string[]>([...intentPrompt]);

  const handleInputChange = (index: number, value: string) => {
    const newPrompt = [...updatedPrompt];

    newPrompt[index] = value;
    setUpdatedPrompt(newPrompt);
  };

  const handleSave = () => {
    onSave(updatedPrompt);
    onClose();
  };

  return (
    <NanoDialog
      open={open}
      title={title}
      width={1200}
      bodyStyle={{ minHeight: '480px' }}
      onClose={onClose}
    >
      <div className="flex flex-col h-full gap-y-14">
        <div className={`flex-grow ${updatedPrompt.length > 5 ? 'max-h-80 overflow-y-auto' : ''}`}>
          {updatedPrompt.map((prompt, index) => (
            <div key={index} className="mb-4">
              <label className="block mb-2 font-bold">
                {t('Prompt')} {index + 1}
              </label>
              <Input
                type="text"
                value={prompt}
                onChange={(e) => handleInputChange(index, e.target.value)}
                className="block w-full"
              />
            </div>
          ))}
        </div>
        <div className="flex justify-end mb-2.5">
          <SubmitButton onClick={handleSave}>Update</SubmitButton>
        </div>
      </div>
    </NanoDialog>
  );
};
