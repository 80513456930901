import { Skeleton } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { Spin, Text, Tooltip, Table, SubmitButton, Input as InputCommon } from 'components/common';

import { defaultBucketState } from 'modules/segment/defaultBucketState';
import { useSegmentsListQuery } from 'modules/segment/list/segmentsListApiSlice';
import { SegmentListItem } from 'modules/segment/segmentApiTypes';
import { TargetingOptionIds } from 'modules/segment/segmentForms/ManualStepForm/targetingOptions';
import { getSegment } from 'modules/segment/segmentSelectors';
import { fetchSegmentDetails, setBuckets, updateSegment } from 'modules/segment/segmentSlice';
import { ManualStepValues } from 'modules/segment/types';

import { composeSegmentByIdRoute } from 'routing/utils';

import { SortIcon } from '../common/icons';
import { DialogBaseProps } from '../dialog';
import { NanoDialog } from '../dialog/NanoDialog/NanoDialog';

export interface SegmentsListDialogProps extends DialogBaseProps {
  intentPrompt: string[];
}

export const SegmentsListDialog = ({ open, onClose, intentPrompt }: SegmentsListDialogProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading: isLoadingSegment, buckets } = useSelector(getSegment);

  const { data: segments, isLoading } = useSegmentsListQuery(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [step, setStep] = useState<number>(1);
  const [intentPromptEdit, setIntentPromptEdit] = useState<string[]>(intentPrompt);
  const [selectedSegmentId, setSelectedSegmentId] = useState<string>(''); // Add selectedSegmentId state

  const handleSearch = (value: string) => {
    setSearchTerm(value.toLowerCase());
  };

  const filteredSegments = segments?.filter(
    (segment) =>
      segment.name.toLowerCase().includes(searchTerm) || segment.id.toString().includes(searchTerm),
  );

  const handleEditSegment = useCallback(
    (id: string, event: React.MouseEvent) => {
      event.stopPropagation();
      setSelectedSegmentId(id);
      dispatch(fetchSegmentDetails({ id }));
      setStep(2);
    },
    [dispatch],
  );

  const columns: ColumnsType<SegmentListItem> = useMemo(
    () => [
      {
        title: t('pages.management.table.segmentName'),
        dataIndex: 'name',
        render: (name, record) => (
          <Tooltip overlay={<div className="p-2">{name}</div>}>
            <Text
              onClick={(event) => handleEditSegment(record.id, event)}
              className="segment-name-link text-base-l font-bold no-underline cursor-pointer"
            >
              {name}
            </Text>
          </Tooltip>
        ),
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortIcon: () => <SortIcon />,
        showSorterTooltip: false,
        ellipsis: true,
        width: '75%',
      },
      {
        title: t('adminPanel.auditLog.searchFormPlaceholder.id'),
        dataIndex: 'id',
        render: (id) => (
          <Tooltip overlay={<div className="p-2">{id}</div>}>
            <Text>{id}</Text>
          </Tooltip>
        ),
        sorter: (a, b) => a.id.localeCompare(b.id),
        sortIcon: () => <SortIcon />,
        showSorterTooltip: false,
        ellipsis: true,
        width: '25%',
      },
    ],
    [handleEditSegment, t],
  );

  const handleInputChange = (index: number, value: string) => {
    const newPrompt = [...intentPromptEdit];

    newPrompt[index] = value;
    setIntentPromptEdit(newPrompt);
  };

  const handleUpdate = () => {
    const keywordsValues = intentPromptEdit;
    const keywordsText = keywordsValues.join(' ');

    const newBucket: ManualStepValues = {
      ...defaultBucketState,
      id: uuid(),
      [TargetingOptionIds.keywords]: {
        keywordsValues,
        keywordsText,
        isTouched: false,
        isIncluded: true,
        intentPrompt: true,
        uploadedValues: [],
      },
    };
    const bucketsEdit: ManualStepValues[] = [...buckets, newBucket];

    dispatch(setBuckets(bucketsEdit));
    dispatch(updateSegment({ id: selectedSegmentId }));
    onClose();
    navigate(composeSegmentByIdRoute(selectedSegmentId));
  };

  return (
    <NanoDialog
      open={open}
      title={step === 1 ? t('nanoBot.chooseSegment') : t('nanoBot.updateIntentPrompt')}
      width={1200}
      bodyStyle={{ minHeight: '600px' }}
      onClose={onClose}
    >
      <div className="w-full overflow-hidden bg-secondary-white">
        <div className="flex flex-col h-[600px]">
          {isLoading ? (
            <Spin className="w-full h-full flex justify-center items-center" />
          ) : (
            <>
              {step === 1 && segments && segments.length > 0 && (
                <>
                  <InputCommon
                    type="text"
                    placeholder={'Search by name or ID'}
                    value={searchTerm}
                    onChange={(e) => handleSearch(e.target.value)}
                    className="w-full max-w-[310px] h-[42px] mb-8"
                  />
                  <Table
                    rowKey="id"
                    pagination={{ pageSize: 10 }}
                    scroll={{ y: 400 }}
                    className="nano-segments-table"
                    columns={columns}
                    dataSource={filteredSegments}
                  />
                </>
              )}
              {step === 2 && (
                <div className="flex flex-col gap-9 justify-center">
                  {isLoadingSegment ? (
                    <Skeleton className="w-full h-full flex justify-center items-center" />
                  ) : (
                    <div>
                      <div
                        className={`flex-grow ${
                          intentPromptEdit.length > 5 ? 'overflow-y-auto' : ''
                        } h-[520px]`}
                      >
                        {intentPromptEdit.map((prompt, index) => (
                          <div key={index} className="mb-4">
                            <label className="block mb-2 font-bold" key={index}>
                              {t('segment.targetingOptions.intentPrompt')} {index + 1}
                            </label>
                            <InputCommon
                              type="text"
                              value={prompt}
                              onChange={(e) => handleInputChange(index, e.target.value)}
                              className="block w-full"
                            />
                          </div>
                        ))}
                      </div>
                      <div className="flex justify-end mt-5 bottom-0">
                        <SubmitButton onClick={handleUpdate}>
                          {t('adminPanel.user.buttons.update')}
                        </SubmitButton>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </NanoDialog>
  );
};
