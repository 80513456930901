import { Input } from 'antd';
import { SendIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { IconButton } from 'components/common';

import { useNanoBotActions } from 'modules/nanoBot/useNanoBotActions';

export const NanoBotMessageInput = () => {
  const { t } = useTranslation();
  const { handleMessageChange, handleMessageSubmit, userMessage } = useNanoBotActions();

  return (
    <form
      onSubmit={handleMessageSubmit}
      className="mx-[30px] my-[30px] flex items-center justify-center gap-[20px]"
    >
      <Input
        name="userMessage"
        value={userMessage}
        onChange={handleMessageChange}
        className="h-[44px] rounded-[48px] bg-primary-dark-purple-80 text-primary-dark-purple-5 placeholder:text-primary-dark-purple-5 flex-1"
        placeholder={t('nanoBot.placeholder')}
      />
      <IconButton className="cursor-pointer p-[5px]" onClick={handleMessageSubmit}>
        <SendIcon size={30} />
      </IconButton>
    </form>
  );
};
