export enum NanoBotChatRole {
  User = 'user',
  Assistant = 'assistant',
}

export enum ActionName {
  CreateSegment = 'create segment',
  UpdateSegment = 'update segment',
  UpdateIntentPrompt = 'update intent prompt',
}
export interface UserMessage {
  [key: string]: string;
}

export interface NanoBotData {
  userMessages: Array<ResponseMessage>;
  message: string;
}

export interface NanoBotState {
  isOpen: boolean;
  messagesHistory: Array<ResponseMessage>;
  currentMessage: string;
  isLoading: boolean;
  cta: CallToAction[] | null;
}

export interface ResponseMessage {
  role: (typeof NanoBotChatRole)[keyof typeof NanoBotChatRole];
  content: string;
}

export interface CallToAction {
  actionName: (typeof ActionName)[keyof typeof ActionName];
  actionValue: string[];
}

export interface NanoBotResponse {
  chat: ResponseMessage[];
  cta: CallToAction[] | null;
}

export interface MessageAction {
  type: string;
  payload: string;
}
